.screen_wrapper {
  background-image: url('../../../public/WaveBG.svg');
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: calc(100vh - 65px);

  background-color: #fafafa;
  padding-left: 160px;
  padding-top: 20px;
  padding-bottom: 150px;
  display: flex;
  gap: 30px;
  position: relative;
}

.add_project_footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #fafafa;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #e0e0e0;
  padding-right: 60px;
}

.button_wrapper {
  width: 140px;
}
.loader_container {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
