.heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height, or 121% */
    color: #000000;
}

.form_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 66%;
    height: 100%;
    gap: 32px;
}

.sub_heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    text-align: center;

    color: #8A8A8A;
}

.form_container {
    max-width: 655px;
    width: 100%;
}

.form_field {
    width: 358px;
}

.button_wrapper {
    margin-top: 32px;
}

.forgot_password {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* identical to box height, or 183% */


    color: #2A72DD;
    margin-top: 12px;
    cursor: pointer;
}

.forgot_password:hover {
    color: #2A72DD;
    text-decoration: underline;
}

.terms_and_policy {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* identical to box height, or 183% */
    color: rgba(138, 138, 138, 0.8);
    text-align: center;
    margin-top: 8px;
}

.cta_link {
    color: #2A72DD;
    text-decoration: underline;
    cursor: pointer;
}