.main_container {
  height: 90vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main_wrapper {
  height: 75vh;
  width: 85vw;
  display: flex;
  gap: 2%;
}
.header {
  display: flex;
  width: 85vw;
  font-family: Lato;
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px 0px;
}
.business_details_wrapper {
  width: 35%;
  padding: 30px;
  overflow-y: scroll;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.map_wrapper {
  width: 63%;
}
.business_wrap {
  padding: 0px;
}
.business_wrap_name {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Lato;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: pink;
}
.business_count {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #646d72;
  margin-bottom: 10px;

  padding: 0px 0px 0px 18px;
}
.circular_hover {
  cursor: pointer;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  margin-right: 10px;
}
.circular_hover:hover {
  cursor: pointer;
  border-radius: 50%;
  background-color: #dce4f1;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.businessNameMarker{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  

}
