.field_label {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  height: 23px;
  /* identical to box height, or 183% */

  color: #8c959b;
  margin-top: 16px;
  margin-bottom: 8px;
}
.avatars {
  display: inline-flex;
  flex-direction: row-reverse;
}
.avatar {
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 35px;
}
.avatar img {
  width: 100%;
  display: block;
}
.avatar:not(:last-child) {
  margin-left: -5px;
}
.user_icon {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
.error_message {
  margin-top: 10px;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #ff0000;
}
