.landing_page_wrapper {
  background: #f7f4f2;
  width: 100%;
  // padding-left: 125px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding-left: 0;
  }
}
.top_container_wrapper_mobile {
  // padding-left: 125px;
  background-image: url('../../../public/landingPageUpdated_mobile.jpg');
  height: 640px;
  background-size: cover;
  border-bottom-right-radius: 135px;

  @media (max-width: 768px) {
    padding: 10px;
  }
}
.top_container_wrapper {
  padding-left: 125px;
  background-image: url('../../../public/landingPageUpdated.svg');
  height: 640px;
  border-bottom-right-radius: 135px;
}
.large_heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 34px;
  margin-top: 70px;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 48px;
    margin-top: 0px;
  }
  img {
    @media (max-width: 768px) {
      margin-bottom: 5px;
      width: 180px;
    }
  }
}

.heading {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;

  /* or 150% */

  color: #000;
  img {
    margin-bottom: 10px;
    width: 175px;
    @media (max-width: 768px) {
      width: 150px;
    }
  }
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 27px;
  }
}

.red {
  color: #d81f1f;
}

.blue {
  color: rgba(25, 45, 115, 1);
}

.heading_section {
  // width: 560px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-start !important;
    margin-top: 80px;
  }
}

.sub_heading {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  margin-top: 10px;

  color: #464240;
}

.field_button_wrapper {
  background: #ffffff;
  border-radius: 8px;

  width: 100%;
  max-width: 507px;
  height: 54px;

  display: flex;
  justify-content: space-between;

  align-items: center;

  padding-right: 8px;

  margin-top: 37px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.input_field {
  border: none;
  outline: none;
  width: 70%;
  height: 100%;

  background-color: transparent;
  padding-left: 13px;
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #2c2c2c;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #2c2c2c;
}

::placeholder {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #2c2c2c;
}

.heading_newsletter {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  height: 100%;
  // padding-right: 125px;

  // @media (max-width: 768px) {
  //   padding-right: 0;
  //   flex-direction: column;
  //   align-items: flex-start;
  // }
}

.features_heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 68px;
  /* identical to box height, or 189% */

  color: #2c2c2c;
  @media (max-width: 768px) {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    /* or 150% */

    color: #2c2c2c;
  }
}

.features_container {
  margin-top: 50px;
  padding-left: 125px;
  padding-right: 125px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media (max-width: 768px) {
    padding: 20px;
  }
}

.individual_feature {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-bottom: 100px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.individual_feature_even {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
}

.detail {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  justify-content: center;
}

.number {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 90px;
  /* identical to box height, or 150% */

  color: #ff6767;
}

.title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */

  color: #2c2c2c;
}

.description {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  /* or 157% */

  text-align: justify;

  color: #464240;
  max-width: 500px;
}

.button_wrapper {
  background: rgba(216, 31, 31, 0.04);
  border: 1px solid #f23737;
  border-radius: 8px;
  width: 140px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #f23737;
}

.image_wrapper {
  position: relative;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  display: flex;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
}

.blue_circle {
  position: absolute;
  width: 367px;
  height: 367px;
  border-radius: 50%;
  background: linear-gradient(
    146.67deg,
    rgba(132, 155, 234, 0.37) 19.84%,
    rgba(255, 255, 255, 0) 153.58%
  );
  left: 175px;
  top: 175px;
}

.left_blue_circle {
  position: absolute;
  width: 367px;
  height: 367px;
  border-radius: 50%;
  background: linear-gradient(
    146.67deg,
    rgba(132, 155, 234, 0.37) 19.84%,
    rgba(255, 255, 255, 0) 153.58%
  );
  right: 175px;
  bottom: 175px;
}

.bottom_page_container {
  background: #f7f4f2;
  width: 100%;
  overflow: hidden;
  padding-top: 100px;

  display: flex;
  justify-content: center;

  flex-direction: column;
}

.cta_wrapper {
  width: 1080px;
  height: 362px;

  background: #192d73;
  border-radius: 14px;

  padding-left: 50px;
  padding-right: 50px;

  display: flex;
  gap: 58px;

  margin: auto;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 341px;
    height: 502px;
    border-radius: 14px;
    padding-left: 36px;
    padding-right: 36px;
    gap: 28px;
    margin-top: 4rem;
  }
}

.cta_image {
  margin-top: -50px;
  border-radius: 8px;
  width: 600px;

  height: 375px;
  @media (max-width: 768px) {
    margin-top: -100px;
    border-radius: 8px;
    width: 269px;
    height: 323px;
    object-fit: cover;
  }
}

.cta_detail_heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  /* or 150% */

  color: #ffffff;
  @media (max-width: 768px) {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: #ffffff;
  }
}

.cta_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cta_button_wrapper {
  background: #ffffff;
  border-radius: 8px;

  width: 135px;
  height: 38px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  margin-top: 26px;

  color: #d81f1f;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-bottom: 3rem;
  }
}

.max_width {
  max-width: 1440px;
  width: 100%;
  overflow: hidden;
}

.testimonial_card {
}

.cards_container {
}

.testimonials {
  margin-top: 100px;
  margin-left: auto;
  width: 985px;
  height: 547px;
  background: #192d73;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  padding-top: 60px;
  padding-left: 69px;
  position: relative;

  // margin-right: -40px;
  &_heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 22px;
    /* identical to box height, or 61% */
    color: #ffffff;
  }

  &_cards {
    position: absolute;
    left: -200px;
    display: flex;
    gap: 10px;
    width: 1068px;
    margin-top: 60px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 0em;
    }

    &::-webkit-scrollbar-track {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
  &_cards_item {
    width: 580px;
    height: 283px;

    background: #ffffff;
    border-radius: 13px;
    flex-shrink: 0;
    padding: 40px;
  }
  .arrow_wrapper {
    position: absolute;
    bottom: 10%;
    left: 40%;
    display: flex;
    gap: 30px;
  }
  @media (max-width: 768px) {
    width: 100vw;
    height: 692px;
    padding: 60px 20px 0px 20px;
    border-radius: 0px;
    &_heading {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 42px;
      /* or 150% */
      color: #ffffff;
    }

    &_cards {
      position: absolute;
      left: 33px;
      display: flex;
      gap: 10px;
      width: 325px;

      overflow-x: scroll;

      &::-webkit-scrollbar {
        width: 0em;
      }

      &::-webkit-scrollbar-track {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        display: none;
      }
    }
    &_cards_item {
      width: 320px;
      height: 354px;

      background: #ffffff;
      border-radius: 13px;
      flex-shrink: 0;
      transition: 1.5s;
    }
  }
}

.testimonial_text {
  height: 180px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */

  color: #2c2c2c;
  overflow: scroll;
  @media (max-width: 768px) {
    height: 250px;
  }
}
.author_image {
  height: 50px;
  width: 50px;
  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
}

.blogs_container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 1197px;
  margin: auto;
  @media (max-width: 768px) {
    width: 100vw;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
}

.blogs_heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 68px;
  /* identical to box height, or 189% */

  color: #2c2c2c;
}

.blog_cards_wrapper {
  display: flex;
  gap: 20px;
  // width: 1068px;
  margin-top: 60px;
  width: 100%;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0em;
  }

  &::-webkit-scrollbar-track {
    display: none;
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    display: none;
    // background-color: darkgrey;
    // outline: 1px solid slategrey;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px 32px;
  }
}

.view_all {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 48px;
  color: #f23737;
}

.heading_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: center;
  }
}

.heading_subheading_wrapper {
  background: #ffffff;
  border-radius: 8px;
  max-width: 507px;
  width: 100%;
  height: 236px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
  gap: 10px;
}
.landing_page_wrapper {
  background: #f7f4f2;
  width: 100%;
  // padding-left: 125px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding-left: 0;
  }
}

.top_container_wrapper {
  padding-left: 125px;
  background-image: url('../../../public/landingPageUpdated.svg');
  height: 640px;
  border-bottom-right-radius: 135px;

  .large_heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 34px;
    margin-top: 70px;
    img {
      margin-bottom: 20px;
      width: 353px;
      @media (max-width: 768px) {
        margin-bottom: 0px;
        width: 245px;
      }
    }

    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 48px;
    }
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
}

.heading {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;

  /* or 150% */

  color: #000;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 27px;
  }
}

.red {
  color: #d81f1f;
}

.blue {
  color: rgba(25, 45, 115, 1);
}

.heading_section {
  // width: 560px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.sub_heading {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  margin-top: 10px;

  color: #464240;
}

.field_button_wrapper {
  background: #ffffff;
  border-radius: 8px;

  width: 100%;
  max-width: 507px;
  height: 54px;

  display: flex;
  justify-content: space-between;

  align-items: center;

  padding-right: 8px;

  margin-top: 37px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.input_field {
  border: none;
  outline: none;
  width: 70%;
  height: 100%;

  background-color: transparent;
  padding-left: 13px;
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #2c2c2c;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #2c2c2c;
}

::placeholder {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #2c2c2c;
}

.heading_newsletter {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  height: 100%;
  // padding-right: 125px;

  // @media (max-width: 768px) {
  //   padding-right: 0;
  //   flex-direction: column;
  //   align-items: flex-start;
  // }
}

.features_heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 68px;
  /* identical to box height, or 189% */

  color: #2c2c2c;
  img {
  }
  @media (max-width: 768px) {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    /* or 150% */

    color: #2c2c2c;
  }
}

.features_container {
  margin-top: 50px;
  padding-left: 125px;
  padding-right: 125px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media (max-width: 768px) {
    padding: 20px;
  }
}

.individual_feature {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.individual_feature_even {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
}

.detail {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  justify-content: center;
}

.number {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 90px;
  /* identical to box height, or 150% */

  color: #ff6767;
}

.title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */

  color: #2c2c2c;
}

.description {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  /* or 157% */

  text-align: justify;

  color: #464240;
  max-width: 500px;
}

.button_wrapper {
  background: rgba(216, 31, 31, 0.04);
  border: 1px solid #f23737;
  border-radius: 8px;
  width: 140px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #f23737;
}

.image_wrapper {
  position: relative;
  display: flex;
  img {
    width: 600px;
  }
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    img {
      width: 350px;
    }
  }
}

.blue_circle {
  position: absolute;
  width: 367px;
  height: 367px;
  border-radius: 50%;
  background: linear-gradient(
    146.67deg,
    rgba(132, 155, 234, 0.37) 19.84%,
    rgba(255, 255, 255, 0) 153.58%
  );
  left: 175px;
  top: 175px;
}

.left_blue_circle {
  position: absolute;
  width: 367px;
  height: 367px;
  border-radius: 50%;
  background: linear-gradient(
    146.67deg,
    rgba(132, 155, 234, 0.37) 19.84%,
    rgba(255, 255, 255, 0) 153.58%
  );
  right: 175px;
  bottom: 175px;
}

.bottom_page_container {
  background: #f7f4f2;
  width: 100%;
  overflow: hidden;
  padding-top: 100px;

  display: flex;
  justify-content: center;

  flex-direction: column;
}

.cta_wrapper {
  width: 1080px;
  height: 362px;

  background: #192d73;
  border-radius: 14px;

  padding-left: 50px;
  padding-right: 50px;

  display: flex;
  gap: 58px;

  margin: auto;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 341px;
    height: 502px;
    border-radius: 14px;
    padding-left: 36px;
    padding-right: 36px;
    gap: 28px;
    margin-top: 4rem;
  }
}

.cta_image {
  margin-top: -50px;
  border-radius: 8px;

  height: 375px;
  @media (max-width: 768px) {
    margin-top: -100px;
    border-radius: 8px;
    height: fit-content;
  }
}

.cta_detail_heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  /* or 150% */

  color: #ffffff;
  @media (max-width: 768px) {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: #ffffff;
  }
}

.cta_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cta_button_wrapper {
  background: #ffffff;
  border-radius: 8px;

  width: 135px;
  height: 38px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  margin-top: 26px;

  color: #d81f1f;

  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    margin-bottom: 3rem;
  }
}

.max_width {
  max-width: 1440px;
  width: 100%;
  overflow: hidden;
}

.testimonial_card {
}

.cards_container {
}

.testimonials {
  margin-top: 100px;
  margin-left: auto;
  width: 985px;
  height: 547px;
  background: #192d73;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  padding-top: 60px;
  padding-left: 69px;
  position: relative;

  // margin-right: -40px;
  &_heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 22px;
    /* identical to box height, or 61% */
    color: #ffffff;
  }

  &_cards {
    position: absolute;
    left: -200px;
    display: flex;
    gap: 10px;
    width: 1172px;
    margin-top: 60px;
    overflow-x: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 0em;
    }

    &::-webkit-scrollbar-track {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
  &_cards_item {
    width: 580px;
    height: 283px;

    background: #ffffff;
    border-radius: 13px;
    flex-shrink: 0;
    padding: 40px;
  }
  .arrow_wrapper {
    position: absolute;
    bottom: 10%;
    left: 10%;
    display: flex;
    gap: 30px;
  }
  @media (max-width: 768px) {
    width: 100vw;
    height: 692px;
    padding: 60px 20px 0px 20px;
    border-radius: 0px;
    &_heading {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 42px;
      /* or 150% */
      color: #ffffff;
      padding: 0px 10px;
    }

    &_cards {
      position: absolute;
      left: 33px;
      display: flex;
      gap: 10px;
      width: 100%;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        width: 0em;
      }
      &::-webkit-scrollbar-track {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        display: none;
      }
    }
    &_cards_item {
      width: 320px;
      height: 380px;

      background: #ffffff;
      border-radius: 13px;
      flex-shrink: 0;
      transition: 1.5s;
      padding: 10px 20px;
    }
    .arrow_wrapper {
      position: absolute;
      bottom: 2%;
      left: 40%;
      display: flex;
      gap: 30px;
    }
  }
}
.blog_author_details {
  display: flex;
  flex-direction: row;
  align-items: center;
  // margin-top: 20px;
  gap: 10px;
}

.author_name {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  /* identical to box height, or 187% */

  color: #000000;
}

.author_date {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  color: #000000;
}

.blogs_container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 1197px;
  margin: auto;
  @media (max-width: 768px) {
    width: 100vw;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
}

.blogs_heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 68px;
  /* identical to box height, or 189% */

  color: #2c2c2c;
}

.blog_cards_wrapper {
  display: flex;
  gap: 20px;
  // width: 1068px;
  margin-top: 60px;
  width: 100%;
  // overflow-x: scroll;
  // scroll-behavior: smooth;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    width: 0em;
  }

  &::-webkit-scrollbar-track {
    display: none;
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    display: none;
    // background-color: darkgrey;
    // outline: 1px solid slategrey;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px 32px;
  }
}

.view_all {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 48px;
  color: #f23737;
}

.heading_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: center;
  }
}

.heading_subheading_wrapper {
  background: #ffffff;
  border-radius: 8px;
  max-width: 507px;
  width: 100%;
  height: 236px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
  gap: 10px;
}
