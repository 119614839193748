.page_wrapper {
  max-width: 970px;
  width: 100%;
  // padding-bottom: 80px;
  background-color: #fafafa;
  // min-height: 100vh;
}

.page_container {
  width: 100vw;
  background-color: #fafafa;
  display: flex;
  padding-top: 25px;
  justify-content: center;
  // padding-bottom: 40px;
  min-height: calc(100vh - 62px);
}

.page_name {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height; or 142% */

  color: #000000;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.secondary_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
}

.secondary_nav_left {
  display: flex;
  align-items: center;
}

.add_member {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  color: #ffffff;
  background: #2a72dd;
  border-radius: 8px;
  border: none;
  width: 135px;
  height: 44px;
}

.secondary_nav_item {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 34px;
  /* identical to box height, or 243% */

  /* Text_Color */
  padding: 20px;
  padding-bottom: 5px;
  color: #667584;
  cursor: pointer;
}

.secondary_nav_item_active {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 34px;
  /* identical to box height, or 243% */

  /* Text_Color */
  padding: 20px;
  padding-bottom: 5px;
  color: #2a72dd;
  border-bottom: 2px solid #2a72dd;
  cursor: pointer;
}

.table {
  border: none;
  width: 964px;
  margin-top: 17px;
}
.table_header {
  width: 964px;
  height: 50px;
  background: #f7f8fb;
  border-radius: 14px 14px 0px 0px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.01em;

  /* Text_Color */

  color: #667584;
  text-align: left;
}

.table_header_checkbox {
  width: 27px;
  // margin-left: 34px;
  text-align: center;
}
.table_body {
  background-color: #ffffff;
}
.table_body_row {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #222222;
  td {
    padding: 15px 0px;
    border: none;
    vertical-align: middle;
  }
}

.status_active {
  width: 54px;
  height: 22px;
  background: rgba(22, 143, 42, 0.1);
  border: 1px solid #168f2a;
  color: #168f2a;
  border-radius: 4px;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status_in_progress {
  width: 77px;
  height: 22px;
  background: rgba(246, 163, 117, 0.1);
  border: 1px solid #f6ab75;
  color: #eb8d37;
  border-radius: 4px;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row_delete {
  width: 30px;
  height: 30px;
  background: rgba(255, 77, 77, 0.1);
  // opacity: 0.1;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all;
  cursor: pointer;
  &:hover {
    box-shadow: rgba(rgb(255, 77, 77), 0.4) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  &:active {
    box-shadow: rgba(rgb(255, 77, 77), 0.4) 0px 6px 12px -5px, rgba(0, 0, 0, 0.3) 0px 3px 7px -7px;
  }
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.row_edit {
  width: 30px;
  height: 30px;
  background: #e3f2fc;
  // opacity: 0.1;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  cursor: pointer;
  &:hover {
    box-shadow: rgba(rgb(42, 153, 221), 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  &:active {
    box-shadow: rgba(rgb(42, 153, 221), 0.25) 0px 6px 12px -5px, rgba(0, 0, 0, 0.3) 0px 3px 7px -7px;
  }
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.add_member {
  margin-left: auto;
}
.add_member:disabled {
  cursor: not-allowed;
}
.circular_hover {
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
}
.circular_hover:hover {
  cursor: pointer;
  border-radius: 50%;
  background-color: #dce4f1;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
