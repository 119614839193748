.header_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 45px;
  padding-bottom: 45px;
  padding-right: 100px;

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
  }
}
.logo_wrapper {
  padding-left: 125px;
  img {
    cursor: pointer;
    @media screen and (min-width: 768px) {
      width: 192px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0;
  }
}

.header_links {
  display: flex;
  gap: 40px;
  align-items: center;
}

.header_link {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  cursor: pointer;
  color: #464240;
  position: relative;
  &:before,
  &:after {
    transition: all 0.3s;
  }
  &:after {
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    text-shadow: none;
    background: #d81f1f;
    height: 1.5px;
    margin-top: 100px;
  }
  &:hover:after {
    width: 100%;
  }

  &:hover {
    color: #d81f1f;
    text-shadow: 0.3px 0 0 #d81f1f;
  }
  &_selected {
    color: #d81f1f;
    text-shadow: 0.3px 0 0 #d81f1f;
    &::after {
      width: 100%;
    }
  }
}

.login_link {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #d81f1f;
  cursor: pointer;
}

.button_wrapper {
  background: rgba(216, 31, 31, 0.04);
  border: 1px solid #d81f1f;
  border-radius: 8px;
  width: 140px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #d81f1f;
  cursor: pointer;
}

.side_menu_mobile {
  // display: none;
  // Add this to show the side menu on mobile when clicked on the hamburger icon
  // @media screen and (max-width: 768px) {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
  z-index: 1000;
  padding: 20px;
  // }
}

.side_menu_mobile {
  animation: slideIn 0.3s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.mobile_menu {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
