.card_wrapper {
  width: 90%;
  height: 180px;
  background: #ffffff;
  border-radius: 8px;
  cursor: pointer;
}
.card_shadow {
  padding: 20px;

  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
}
.top_wrapper {
  height: 120px;
  display: flex;
  flex-direction: column;
  .header {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #646d72;
    margin-bottom: 1rem;
  }
  .value_sent {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    color: #222222;
  }
  .value_sent_percent {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    // color: #393b3d;
    line-height: 22px;
    color: #646d72;
  }
  .outOf {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #667584;
    margin-top: 5px;
  }
}
.progressBar_wrapper {
  height: 60px;
  width: 100%;
  .progressBar_container {
    height: 20px;
    width: 100%;
    // border-radius: 10px;
    background: #e3dfdf;
  }
  .progressBar {
    height: 20px;
    // border-radius: 10px;
  }
}
