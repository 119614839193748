.two_fields {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
}

.single_field {
  width: 292px;
}

.form_wrapper {
  max-height: 600px;
  overflow-y: scroll;
  padding: 0 20px;
  padding-bottom: 20px;
}

.button_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  gap: 20px;
}

.submit_button {
  width: fit-content;
  padding-left: 22px;
  padding-right: 22px;
}

.checkbox_label {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #282842;
  width: 500px;
}
.checkbox_label_description {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  letter-spacing: 0.01em;

  color: #7f7f89;
  position: absolute;
  left: 0;
  width: 500px;
}
.add_more {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  color: #2a72dd;
  margin-top: 16px;
  cursor: pointer;
}
