//
.file_uploader_container {
  width: 100%;
  min-height: 190px;
  // height: 265px;
  background: #fafcff;
  border: 2px dashed #8ec1ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.error_msg {
  margin-top: 1rem;
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  // text-align: center;
  max-width: fit-content;

  /* CC – White */
  color: #ffffff;
  background: #e4513d;
  padding: 10px 14px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.not_sure_underline {
  height: 0.25px;
  width: 175px;
  background: #777777;
}

.browse_button {
  height: 40px;
  width: 198px;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  text-align: center;

  color: #114b5f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file_uploader_text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-decoration-line: underline;

  color: #2a72dd;
}

.file_upload_types {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #777777;
  margin-top: 5px;
  margin-bottom: 5px;
}

.upload_wrapper {
  display: flex;
  width: 100%;
  // align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.upload_button {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  width: 190px;
  height: 220px;
  margin: 10px;

  // box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.15);

  cursor: pointer;

  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  .uploaded_file_header {
    height: 41px;
    background: rgba(0, 0, 0, 0.1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .upload_button:first-child {
    margin-left: 0px;
  }
  .uploaded_file {
    height: 82%;
  }
  .uploaded_file_bottom {
    height: 41px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  }

  .uploaded_file_bottom .file_name {
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.delete_icon {
  height: 20px;
  width: 20px;
}
.account_statement {
  width: 100%;
  height: 100%;
}
.uploaded_file_container {
  display: flex;
  flex-wrap: wrap;
}
.download_file {
  display: flex;
  width: 100%;
  justify-content: end;
  a {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    margin-top: 0.5rem;
    color: #2a72dd;
  }
}
