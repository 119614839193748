.button_wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.submit_button {
  width: fit-content;
  padding-left: 32px;
  padding-right: 32px;
}
