.landing_page_wrapper {
  background: #f7f4f2;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding-left: 0;
  }
}

.max_width {
  width: 100%;
  overflow: hidden;
}

.page_wrapper {
  padding: 20px;

  @media (max-width: 768px) {
    padding: 0px;
  }
}

.heading_cover_image {
  width: 977px;
  height: 250px;

  background: #192d73;
  border-radius: 12px;

  overflow: visible;

  @media (max-width: 768px) {
    width: 345px;
  }
}

.content_wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 64px;
  /* identical to box height, or 152% */

  color: #ffffff;

  margin-bottom: 26px;
  margin-top: 40px;
  @media (max-width: 768px) {
    width: 224px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    /* or 150% */

    text-align: center;

    color: #ffffff;
  }
}

.sub_heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  color: #ffffff;
  @media (max-width: 768px) {
    width: 213px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    text-align: center;

    color: #ffffff;
  }
}

.heading_cover_image {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.search_field_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  background: #ffffff;
  border-radius: 8px;
  margin-top: 70px;
  width: 720px;
  padding-right: 16px;
  padding-left: 26px;
  @media (max-width: 768px) {
    width: 321px;
    height: 60px;
    margin-top: 2px;
    padding-right: 16px;
    padding-left: 8px;
  }
}

.input_field {
  border: 0;
  outline: none;
  background-color: transparent;
  width: 100%;
  height: 60px;
  padding-left: 10px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #464240;
  @media (max-width: 768px) {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 22px;
    /* identical to box height, or 220% */

    color: #464240;
  }
}

.search_button {
  background: rgba(216, 31, 31, 0.04);
  border: 1px solid #d81f1f;
  border-radius: 8px;
  width: 130px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  /* identical to box height, or 157% */

  color: #d81f1f;
  @media (max-width: 768px) {
    display: none;
  }
}

.codes_dropdown_container {
  margin-top: 60px;
  display: flex;
  max-width: 800px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
  // margin-bottom: 1000px;
  @media (max-width: 768px) {
    width: 320px;
  }
}

.dropdown {
  background: #ffffff;
  border-radius: 8px;
  width: 381px;
  padding-right: 26px;
  padding-left: 16px;
  padding-top: 7px;
  padding-bottom: 7px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 58px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 320px;
  }
}

.active_dropdown {
  // Set background to change in 0.5sec slowly
  transition: background 0.5s ease-in-out;

  background: rgba(216, 31, 31, 0.04);

  outline: 1px solid #d81f1f;
}

.index_wrapper {
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #7a82cb;
  border-radius: 10px;

  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #ffffff;
}

.dropdown_text {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #464240;
  width: 100%;
  display: flex;
  // gap: 10px;
  justify-content: space-between;
}
.dropdown_text_item {
  width: 60%;
}

.results {
  background: #ffffff;
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  width: 463px;
  min-height: 100px;
  max-height: 250px;
  overflow-y: scroll;
  // width: 100%;

  z-index: 1;
  position: absolute;
  right: 0;

  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 15px;
  @media (max-width: 768px) {
    width: 320px;
  }

  .result {
    padding-left: 23px;
    padding-right: 23px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    color: #464240;
    &:hover {
      background-color: #cbd8f2;
      cursor: pointer;
    }
  }
}

.dropdown_and_results_wrapper {
  position: relative;
}

.display_none {
  display: none;
}

.most_popular {
  border-radius: 8px;
  background: #ff4140;
  width: 112px;
  height: 30px;
  color: #fff;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 220% */
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
