.chooseFromProject_container {
  display: flex;
  width: 745px;
  flex-wrap: wrap;
  height: 321px;
  overflow-y: scroll;
}

.upload_button {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  width: 209px;
  height: 220px;
  margin: 1rem;

  // box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.15);

  cursor: pointer;

  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  &:hover {
  }
  .uploaded_file_header {
    height: 41px;
    background: rgba(0, 0, 0, 0.1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .upload_button:first-child {
    margin-left: 0px;
  }
  .uploaded_file {
    height: 81%;
  }
  .uploaded_file_bottom {
    height: 41px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  }

  .uploaded_file_bottom .file_name {
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.delete_icon {
  height: 20px;
  width: 20px;
}
.account_statement {
  width: 100%;
  height: 100%;
}
.uploaded_file_container {
  display: flex;
  flex-wrap: wrap;
}
