.dragAndDrop_container {
  min-height: 310px !important;
  // padding: 1rem;
}

.form_wrapper {
  padding: 0 20px;
  padding-bottom: 20px;
}

.button_wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.ChoosefromDashboard_btn {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
}

.submit_button {
  width: fit-content;
  padding-left: 22px;
  padding-right: 22px;
}

.checkbox_label {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #282842;
  width: 500px;
}
.checkbox_label_description {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  letter-spacing: 0.01em;

  color: #7f7f89;
  position: absolute;
  left: 0;
  width: 500px;
}
.file_label {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  // margin-top: 40px;
}
.or_underline {
  height: 0.25px;
  width: 175px;
  background: #f2f2f2;
}
.label_styles {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding: 0px 1rem;
  /* identical to box height, or 150% */

  color: #222222;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-align: center;

  /* CC – Dark Grey */

  color: #777777;
}
.applied_filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;

  // width: 92px;
  height: 28px;

  background: #eaeef4;
  cursor: pointer;
  // border: 1px solid #cdd5dd;
  // border-radius: 100px;
}
.applied_filters {
  display: flex;
  flex-direction: row;
  gap: 10px;
  // align-items: center;
  // justify-content: center;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  // max-height: 60px;
  flex-wrap: wrap;

  // max-width: 1440px;
  // width: 1440px;
  margin: 0px auto;

  /* Text_Color */

  color: #667584;
  margin-bottom: 5px;
  .applied_filter_close {
    cursor: pointer;
  }
}
.applied_filter_text {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer !important;
}

.clear_filters_text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #2a72dd;
}
.circular_hover {
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
}
.circular_hover:hover {
  cursor: pointer;
  border-radius: 50%;
  background-color: #dce4f1;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
