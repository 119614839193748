.cutomisedTextField {
  width: 100%;
}

.field_label {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #8c959b;
  margin-top: 16px;
  margin-bottom: 8px;
}

.error_message {
  margin-top: 10px;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #ff0000;
}
