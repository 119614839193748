.landing_page_wrapper {
  background: #f7f4f2;
  width: 100%;
  // max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding-left: 0;
  }
}

.max_width {
  max-width: 1440px;
  width: 100%;
  overflow: hidden;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding-left: 125px;

  gap: 100px;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 46px;
  &_text {
    max-width: 472px;
    height: 126px;
    &_title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 42px;
      line-height: 63px;
      /* or 150% */

      color: #2c2c2c;
      margin-bottom: 34px;
    }
    &_description {
      font-family: 'Cabin';
      font-style: normal;
      color: #2c2c2c;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 144.444% */
      & span {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
      }
    }
  }
  &_image {
    // padding-right: 100px;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin-top: 20px;
    &_text {
      width: 320px;
      height: 178px;
      &_title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        color: #2c2c2c;
        margin-bottom: 19px;
      }
      &_description {
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #464240;
      }
    }
    &_image {
      display: flex;
      justify-content: center;
      width: 320px;
      height: 415px;
      padding: 0px 24px;
      background-color: #ffffff;
      // padding-right: 100px;
      img {
        width: 320px;
        height: 415px;
      }
    }
  }
}

.features {
  margin: auto;
  margin-top: 100px;
  width: 100%;
  max-width: 1050px;
  &_header {
    margin-bottom: 45px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;

    /* identical to box height, or 142% */
    // padding-left: 125px;
    color: #2c2c2c;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    width: 320px;
    &_header {
      margin-bottom: 45px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      /* identical to box height, or 142% */
      // padding-left: 125px;
      color: #2c2c2c;
    }
  }
}

.card_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  row-gap: 28px;
  column-gap: 20px;
  flex-wrap: wrap;
  // padding-left: 125px;
}
.overlay:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to top, #000000, #ffffff00);
  opacity: 0.5;
  z-index: 300;
}
.overlay {
  position: relative;
  display: block;
}
.card {
  width: 327px;
  height: 348.9px;
  background: #ffffff;
  border-radius: 12px;
  // padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  &_shadow {
    width: 327px;
    height: 348.9px;
    border-radius: 12px;
    position: absolute;
    left: 0;
    top: 0;
    transition-delay: 101.42854021316707ms;
    transition-duration: 249.24795599354906ms;
    transition-property: all;
    &:hover {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    }
  }
  &_icon {
    width: 100%;
  }

  &_title {
    display: flex;
    align-items: center;
    height: 63px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    color: #ffffff;
    margin-top: -65px;
    margin-left: 22px;
    z-index: 99999;
  }
  &_description {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    text-align: justify;

    color: #464240;
    // margin-top: 10px;
  }
}

.bottom_page_container {
  background: #f7f4f2;
  width: 100%;
  overflow: hidden;
  padding-top: 250px;

  display: flex;
  justify-content: center;

  flex-direction: column;
  @media (max-width: 768px) {
    width: 100vw;
    background: #f7f4f2;

    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.cta_wrapper {
  width: 1080px;
  height: 362px;

  background: #192d73;
  border-radius: 14px;

  padding-left: 50px;
  padding-right: 50px;

  display: flex;
  gap: 58px;

  margin: auto;
  margin-bottom: 60px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 341px;
    height: 502px;
    border-radius: 14px;
    padding-left: 36px;
    padding-right: 36px;
    gap: 28px;
  }
}

.cta_image {
  margin-top: -50px;
  border-radius: 8px;
  object-fit: cover;
  width: 520px;
  height: 375px;
  @media (max-width: 768px) {
    width: 270px;
    object-fit: cover;
    margin-top: -100px;
  }
}
.hero_image{
  @media(max-width:768px){
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.cta_detail_heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  /* or 150% */

  color: #ffffff;
  @media (max-width: 768px) {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: #ffffff;
   
  }
}

.cta_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cta_button_wrapper {
  background: #ffffff;
  border-radius: 8px;

  width: 135px;
  height: 38px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  margin-top: 26px;

  color: #d81f1f;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    margin-bottom: 3rem;
  }
}
