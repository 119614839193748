.page_wrapper {
  padding: 20px;
  @media (max-width: 768px) {
    padding: 0px;
  }
}

.blogs_page_wrapper {
  background: #f7f4f2;
  width: 100%;
  // padding-left: 125px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding-left: 0;
  }
}

.max_width {
  max-width: 1440px;
  width: 100%;
  overflow: hidden;
}

.cover_image_wrapper {
  position: relative;
  margin-bottom: 200px;

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .cover_image {
    width: 980px;
    height: 330px;
    @media (max-width: 768px) {
      width: 322px;
      height: 330px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}

.cover_blog_details {
  // max-width: 457px;
  width: 457px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: #ffffff;
  border-radius: 8px;
  position: absolute;

  bottom: 0;
  right: 45px;

  padding: 24px;

  max-height: 267px;
  margin-bottom: -135px;

  @media (max-width: 768px) {
    // Center the blog details
    right: 0;
    left: 0;
    margin: 0 auto;
    margin-bottom: -220px;
    max-width: 290px;
    max-height: 450px;
  }
}

.cover_blog_heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  /* or 150% */

  color: #2c2c2c;
}

.cover_blog_sub_heading {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  text-align: justify;

  color: #464240;
}

.blog_author_details {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.author_name {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  /* identical to box height, or 187% */

  color: #000000;
}

.author_date {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  color: #000000;
}

.tags_nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;

  margin-bottom: 48px;

  // Responsive, should be scrollable on mobile if there are too many tags
  @media (max-width: 768px) {
    overflow-x: scroll;
    gap: 10px;
    max-width: 100%;
    padding-left: 20px;
    justify-content: flex-start;
    margin-top: 100px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.tag {
  background: rgba(216, 31, 31, 0.05);
  border-radius: 100px;

  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #464240;
  padding: 9px 14px;

  cursor: pointer;
}

.active {
  background: #d81f1f;
  color: #ffffff;
  padding: 9px 21px;
}

.blogs_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: center;
  gap: 20px;
  align-items: flex-start;
  
width: 1180px;
  max-width: 1180px;

  @media (max-width: 768px) {
    max-width: 390px;
    margin-top: 80px;
    justify-content: center;
  }
}

.load_more {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  margin-top: 50px;
  color: #d81f1f;
  cursor: pointer;
  margin-bottom: 93px;
}
