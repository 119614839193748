.business_details {
  display: flex;
  &_left {
    width: calc(100% - 58px);
    margin-right: 10px;
  }
  &_right {
    width: calc(100% - 58px);
  }
  margin-bottom: 20x;
}

.section_header {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  /* identical to box height, or 214% */

  color: #222222;
  margin-bottom: 16px;
  //   margin-top: 10px;
}
