@mixin btn($bg, $boder-col: $bg) {
  cursor: pointer;

  background: $bg;
  border: 1px solid $boder-col;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover:not(:disabled) {
    background: darken($bg, 8%);
    transition: all 0.3s ease;
  }
  &:active {
    background: darken($bg, 25%);
  }
  &:disabled {
    cursor: not-allowed;
    background: darken($bg, 8%);
    opacity: 0.6;
    // border-color: lighten($bg, 8%);
    &:hover {
      box-shadow: none;
    }
  }
}
@mixin links($col) {
  cursor: pointer;
  & {
    color: $col;
    &:hover {
      color: darken($col, 8%);
      transition: all 0.3s ease;
    }
    &:active,
    &:focus {
      color: darken($col, 25%);
    }
  }
}
