@import '../../sassStyles/mixins';
.main_container {
  display: flex;
  width: 100%;
  height: calc(100vh - 167px);
  background: #f5f5f5;
  gap: 20px;
}
.main_container_template {
  display: flex;
  width: 100%;
  height: calc(100vh - 62px);
  background: #f5f5f5;
  gap: 20px;
}
.left_column {
  width: 30%;
  // height: 100%;
  margin: 25px 0px 0px 25px;
  border-radius: 8px ;
  background-color: #ffffff;
  padding: 24px;
  .template_list_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    .create_template {
      text-decoration: underline;
      @include links(#2a72dd);
      color: #2a72dd;
    }
  }
  .list_header {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    color: #222222;
  }
}
.left_column_template {
  width: 30%;
  // height: 100%;
  margin: 25px 0px 25px 25px;
  border-radius: 8px ;
  background-color: #ffffff;
  padding: 24px;
  .template_list_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    .create_template {
      text-decoration: underline;
      @include links(#2a72dd);
      color: #2a72dd;
    }
  }
  .list_header {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    color: #222222;
  }
}
.list_container {
  // height: 90%;
  margin-top: 22px;
  overflow-y: auto;

  .list {
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    color: #384852;
    border-bottom: 1px solid #f2f2f2;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    &:hover {
      background: #f2f2f2;
    }
  }
}
.bussiness_list {
  height: 60px;
  cursor: pointer;
  display: flex;
  padding-left: 5px;
  color: #384852;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  .business_name {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #222222;
  }
  .business_email {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 22px;
    /* identical to box height, or 200% */

    letter-spacing: 0.01em;

    color: #384852;
  }
}

.list_selected {
  background: #f2f2f2;
}
.right_column {
  width: 70%;
  background: #f5f5f5;
  // height: 100%;
}
.right_column_template {
  width: 70%;
  background: #f5f5f5;
  margin-bottom: 25px;
  // height: 100%;
}

.row_edit {
  width: 30px;
  height: 30px;
  background: #e3f2fc;
  margin-right: 10px;
  // opacity: 0.1;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: rgba(rgb(42, 153, 221), 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  &:active {
    box-shadow: rgba(rgb(42, 153, 221), 0.25) 0px 6px 12px -5px, rgba(0, 0, 0, 0.3) 0px 3px 7px -7px;
  }
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.row_delete {
  width: 30px;
  height: 30px;
  background: rgba(255, 77, 77, 0.1);
  // opacity: 0.1;
  border-radius: 100px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all;
  &:hover {
    box-shadow: rgba(rgb(255, 77, 77), 0.4) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  &:active {
    box-shadow: rgba(rgb(255, 77, 77), 0.4) 0px 6px 12px -5px, rgba(0, 0, 0, 0.3) 0px 3px 7px -7px;
  }
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background: #d0d0d0;
  border-radius: 100px;
}
.circular_hover {
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
}
.circular_hover:hover {
  cursor: pointer;
  border-radius: 50%;
  background-color: #dce4f1;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
