.form_container {
  // margin-top: 4rem;
  padding: 18px 16px;
  height: fit-content;
  gap: 13px;
  background: #ffffff;
  border-radius: 14px;
  min-width: 785px;
  max-width: 785px;
  margin-bottom: 4rem;

  // margin-bottom: 4rem;
}

.form_title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */

  color: #222222;
}

.form_description {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #384852;
}

.form_field {
  width: 757px;
}

.two_fields {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
}

.three_fields {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
}

.single_field {
  width: 360px;
}

.add_project_footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.button_wrapper {
  display: flex;
  width: 296px;

  button {
    max-width: 140px;
  }

  button:first-of-type {
    margin-right: 16px;
  }
}

.add_filer {
  background: #ffffff;
  border: 1px solid #2a72dd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 16px;
  cursor: pointer;
}

.buttons_wrapper {
  display: flex;
  width: 280px;
  justify-content: space-between;

  margin-top: 34px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #2a72dd;
}

.cards_wrapper {
  display: flex;
  gap: 20px;
  margin-top: 35px;

  .card {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 15px;

    max-width: 280px;
    width: 100%;
  }

  .card_title_radio {
    display: flex;

    justify-content: space-between;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;

    color: #222222;
  }

  .card_description {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    margin-top: 15px;
    color: rgba(56, 72, 82, 0.5);
  }
}

.form_schedule_later {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.switch_button {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 18px;
  margin-left: 0px;
  width: 120px;
  justify-content: space-between;
}
.switch_button_desc {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  /* identical to box height, or 186% */
}
.form_schedule_later {
  display: flex;
  flex-direction: column;
  gap: 20px;

  color: #222222;
}
.select_dates {
  display: flex;
  flex-direction: column;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #384852;
  gap: 5px;
}
.selectedDate {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.01em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;

  height: 28px;
  background: #eaeef4;
  border: 1px solid #cdd5dd;
  border-radius: 100px;
}
