.stepper_wrapper {
  min-width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 16px;
  height: fit-content;
  gap: 13px;
  border-radius: 14px;
  width: 250px;
  background: #ffffff;
  border-radius: 14px;
}

.stepper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step_circle_wrapper {
  background: #ffffff;
  border: 1px solid #dee2e6;
  box-sizing: border-box;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

}

.step_line {
  width: 1px;
  height: 30px;
  background: #dee2e6;
}

.automation_step_line {
  width: 1px;
  height: 88px;
  background: #dee2e6;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 38px;
}

.automation_title {
  display: flex;
  flex-direction: column;
  gap: 98px;
}

.step_title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  color: #7e7a7a;

  cursor: pointer;

}

.step_title_active {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  color: #2a72dd;

  cursor: pointer;

}

.step_circle_wrapper_active {
  background: #2a72dd;
  border: 2px solid #cee7ff;
  box-sizing: border-box;
  border-radius: 50%;
  color: #ffffff;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

}
