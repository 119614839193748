//pill
.business_detail_pill {
  font-family: 'Lato';
  font-style: normal;
  line-height: 22px;
  margin-bottom: 20px;
  &_header {
    font-weight: 400;
    font-size: 12px;

    color: #384852;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &_value {
    font-weight: 500;
    font-size: 14px;

    color: #222222;
  }
}

.checkbox {
  --background: #fff;
  --border: #d1d6ee;
  --border-hover: #bbc1e1;
  --border-active: #2a72dd;
  --tick: #fff;
  position: relative;

  input,
  svg {
    width: 16px;
    height: 16px;
    display: block;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: var(--background);
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    transition: box-shadow 0.3s;
    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

    &:hover {
      --s: 2px;
      --b: var(--border-hover);
    }

    &:checked {
      --b: var(--border-active);
    }
  }

  svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, var(--border-active));
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    transform: scale(var(--scale, 1)) translateZ(0);
  }

  &.path {
    input {
      &:checked {
        --s: 2px;
        transition-delay: 0.4s;

        & + svg {
          --a: 16.1 86.12;
          --o: 102.22;
        }
      }
    }

    svg {
      stroke-dasharray: var(--a, 86.12);
      stroke-dashoffset: var(--o, 86.12);
      transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
    }
  }

  &.bounce {
    --stroke: var(--tick);

    input {
      &:checked {
        --s: 11px;

        & + svg {
          animation: bounce 0.4s linear forwards 0.2s;
        }
      }
    }

    svg {
      --scale: 0;
    }
  }
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }

  75% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}
.checkbox_options_wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-right: 8px;
}

.business_detail_pill_value {
  width: 215px;
  height: fit-content;
  // left: 0px;
  // top: 35px;
  overflow: hidden;
  text-overflow: ellipsis;

  background: #eaedf6;
  border-radius: 8px;
  padding: 12px;
  position: relative;

  p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* identical to box height, or 183% */

    color: #384852;
    margin-bottom: 2px;
  }
  span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    color: #222222;
    width: calc(200px - 12px);
  }
  .row_delete {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 30px;
    height: 30px;
    // background: rgba(255, 77, 77, 0.1);
    // opacity: 0.1;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all;
    cursor: pointer;
    &:hover {
      // box-shadow: rgba(rgb(255, 77, 77), 0.4) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
    &:active {
      // box-shadow: rgba(rgb(255, 77, 77), 0.4) 0px 6px 12px -5px, rgba(0, 0, 0, 0.3) 0px 3px 7px -7px;
    }
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
}
.business_detail_pill_header_hover:hover {
  background-color: #eaedf6;
  cursor: pointer;
  // padding: 1px;
}
