.main_wrapper {
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.heading {
  // width: 50%;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #000000;
}
.filter {
  display: flex;
  // min-width: 22%;
  align-items: center;
  justify-content: space-around;
  gap: 15px;
  .view_by {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* identical to box height, or 183% */

    color: #8c959b;
    padding-right: 5px;
  }
}
.input_element {
  height: 35px;
  border-radius: 5px;
  width: 200px;
  margin-right: 10px;
  &:focus {
    outline: 2px solid #2684ff;
  }
}
.button_wrapper {
  width: 150px;
  height: 40px;
  display: flex;
  align-items: center;
  .submit_button {
    height: 34px;
  }
}
.submit_button {
  width: 130px;
}
