.blog_card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 23px;
  gap: 10px;

  width: 279px;
  height: 400px;

  background: #ffffff;
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  position: relative;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 319px;
    height: 460px;
    margin: 0px auto 0px auto;
  }
}

.blog_heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */

  color: #2c2c2c;
  height: 84px;
}

.blog_content {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  color: #464240;

  height: 66px;
  text-overflow: clip;
  display: -webkit-box;
  overflow-y: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.read_more_button {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #f23737;
  cursor: pointer;
}

.blog_author_details {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.author_name {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  /* identical to box height, or 187% */

  color: #000000;
}

.author_date {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  color: #000000;
}

.blog_image {
  width: 100%;
  // width: 252px;
  height: 181px;
  overflow: hidden;

  img {
    border: none;
    height: 100%;
    width: 100%;
    // object-fit: contain;
    object-fit: cover;
    border-radius: 12px;
  }
}
