.name {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}
.description {
  max-width: 265px;
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.8);
  text-align: justify;
}
.image {
  border-radius: 50%;
  margin-right: 16px;
}
.featurecontainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
