.linear_gradient_bg {
  border-radius: 14px 14px 0px 0px;
  background: linear-gradient(25.05deg, #2a72dd 0%, #2a99dd 111.66%);
  height: 101px;
}

.profile_wrapper {
  margin-top: 24px;
  background-color: #ffffff;
  padding-bottom: 20px;
}

.profile_picture {
  width: 92px;
  height: 92px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-left: 24px;
  margin-top: -48px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  // background-color: #000000;
  /* identical to box height, or 150% */

  color: #000000;

  position: relative;
}

.profile_picture i {
  width: 92px;
  height: 92px;
  cursor: pointer;
  color: #ffffff;
}

.profile_picture i:hover {
  opacity: 0.4;
  filter: grayscale(100%);
}

.edit_icon {
  position: absolute;
  right: -5px;
  top: 6px;
  background: #ffffff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.edit_details_form {
  margin-left: 150px;
  margin-top: -50px;
  padding-right: 52px;
}

.heading {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */

  color: #000000;
  margin-top: 20px;
}

.two_fields {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
}

.single_field {
  display: inline-block;
  width: 346px;

  @media screen and (max-width: 800px) {
    max-width: 600px;
    width: 100%;
    margin-bottom: 20px;
  }
}

.form_wrapper {
  width: 100%;
}

.change_password_link {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #2a72dd;

  cursor: pointer;
  margin-top: 17px;
}

.button_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  gap: 20px;
}

.button_save_changes {
  width: 149px;
}

.save_botton {
  width: 98px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  // font-family: 'Roboto', 'Segoe UI';
}

.connect_account_button {
  width: 150px;
  // margin-top: 24px;
}

.gsimaterialbutton {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsimaterialbutton .gsimaterialbuttonicon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsimaterialbutton .gsimaterialbuttoncontentwrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsimaterialbutton .gsimaterialbuttoncontents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsimaterialbutton .gsimaterialbuttonstate {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsimaterialbutton:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsimaterialbutton:disabled .gsimaterialbuttoncontents {
  opacity: 38%;
}

.gsimaterialbutton:disabled .gsimaterialbuttonicon {
  opacity: 38%;
}

.gsimaterialbutton:not(:disabled):active .gsimaterialbuttonstate,
.gsimaterialbutton:not(:disabled):focus .gsimaterialbuttonstate {
  background-color: #303030;
  opacity: 12%;
}

.gsimaterialbutton:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsimaterialbutton:not(:disabled):hover .gsimaterialbuttonstate {
  background-color: #303030;
  opacity: 8%;
}
