.two_fields {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 0px;
  }

  margin-top: 20px;
}

.single_field {
  width: 292px;
}

.form_wrapper {
  padding: 0 20px;
  padding-bottom: 20px;
}

.button_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  gap: 20px;
}

.submit_button {
  width: fit-content;
  padding-left: 22px;
  padding-right: 22px;
}

.checkbox_label {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #282842;
  width: 500px;
}

.checkbox_label_description {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  letter-spacing: 0.01em;

  color: #7f7f89;
  position: absolute;
  left: 0;
  width: 500px;
}

.form_heading {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  color: #222222;
  margin-top: 20px;
}

.state_dropdown {
  background: #fefefe;
  border: 1px solid #d1dadf;
  border-radius: 4px;
  height: 30px;
  min-width: 212px;

  // Style font for dropdown
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  padding-left: 10px;
  padding-right: 10px;
  /* identical to box height, or 183% */

  color: #384852;

  // Style for dropdown options
  .dropdown_options {
    background: #fefefe;
    border: 1px solid #d1dadf;
    border-radius: 4px;
    height: 30px;

    // Style font for dropdown
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    padding-left: 10px;
    /* identical to box height, or 183% */
  }
}

.range_slider {
  width: 100%;
  display: flex;
  justify-content: center;
}

.checkbox_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox_label_description {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Text_Color */

  color: #667584;
}

.checkbox_options_wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.option_label {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Text_Color */

  color: #667584;
  width: 76px;
}

.checkbox {
  --background: #fff;
  --border: #d1d6ee;
  --border-hover: #bbc1e1;
  --border-active: #2a72dd;
  --tick: #fff;
  position: relative;

  input,
  svg {
    width: 21px;
    height: 21px;
    display: block;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: var(--background);
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    transition: box-shadow 0.3s;
    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

    &:hover {
      --s: 2px;
      --b: var(--border-hover);
    }

    &:checked {
      --b: var(--border-active);
    }
  }

  svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, var(--border-active));
    position: absolute;
    top: 0;
    left: 0;
    width: 21px;
    height: 21px;
    transform: scale(var(--scale, 1)) translateZ(0);
  }

  &.path {
    input {
      &:checked {
        --s: 2px;
        transition-delay: 0.4s;

        & + svg {
          --a: 16.1 86.12;
          --o: 102.22;
        }
      }
    }

    svg {
      stroke-dasharray: var(--a, 86.12);
      stroke-dashoffset: var(--o, 86.12);
      transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
    }
  }

  &.bounce {
    --stroke: var(--tick);

    input {
      &:checked {
        --s: 11px;

        & + svg {
          animation: bounce 0.4s linear forwards 0.2s;
        }
      }
    }

    svg {
      --scale: 0;
    }
  }
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }

  75% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.checkboxes_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 350px;
  padding-right: 15px;
  overflow-y: scroll;
}

.show_more_button {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #2a72dd;
  margin-top: 20px;

  cursor: pointer;
}
.min_max_range_slider {
  // width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.value_button {
  background: #fefefe;
  border: 1px solid #d1dadf;
  border-radius: 4px;
  width: 150px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #384852;
  padding-left: 10px;
  padding-right: 10px;
}

.value_button:hover {
  cursor: pointer;
}
