.form_container {
  padding: 18px 16px;
  height: fit-content;
  gap: 13px;
  background: #ffffff;
  border-radius: 14px;
  min-width: 700px;
  margin-bottom: 20px;
}
.form_title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  margin-top: 10px;
  /* identical to box height, or 127% */

  color: #222222;
}

.note {
  background: #f5f9ff;
  border-radius: 8px;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  padding-left: 12px;
  margin-top: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 27px;
  color: #637692;
  margin-bottom: 12px;
}
.add_more {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  color: #2a72dd;
  margin-top: 16px;
  cursor: pointer;
}
.two_fields {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
}

.single_field {
}

.form_wrapper {
  padding: 0 20px;
  padding-bottom: 20px;
  height: 515px;
  overflow-y: scroll;
}

.button_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  gap: 20px;
}

.submit_button {
  width: fit-content;
  padding-left: 22px;
  padding-right: 22px;
}

.selected_code_footer {
  width: 100%;
  height: 70px;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.05);
  position: sticky;
  display: flex;
  align-items: center;
  padding-right: 20px;
  justify-content: flex-end;
  cursor: pointer;
}
.button {
  background: rgba(216, 31, 31, 0.04);
  border: 1px solid #d81f1f;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 120px;
  height: 40px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  /* identical to box height, or 157% */

  color: #d81f1f;
}
