body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

abbr[title] {
  /* -webkit-text-decoration: underline dotted; */
  text-decoration: none !important;
  cursor: default !important;
  text-decoration-skip-ink: none;
}
.mapboxgl-popup-close-button {
  display: none;
}
.mapboxgl-popup-content{
  display: flex;
  justify-content: center;
  height: 36px;
  /* width: 50px; */
}
