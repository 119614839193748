.page_wrapper {
  height: 100vh;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 0px;
  }
}

.blogs_page_wrapper {
  background: #f7f4f2;
  width: 100%;
  
  // padding-left: 125px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding-left: 0;
  }
}

.max_width {
  max-width: 1440px;
  width: 100%;
}

.blog_detail_wrapper {
  max-width: 980px;
  min-height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  margin: auto;
}

.author_date {
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  color: #2c2c2c;

  .date_posted {
    font-weight: 600;
  }
}

.blog_title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 63px;
  /* or 150% */

  text-align: center;

  color: #2c2c2c;

  max-width: 650px;
  margin-top: 10px;
}

.sub_heading {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  text-align: center;

  color: #464240;

  max-width: 550px;
  margin-top: 10px;
}

.cover_image {
  object-fit: cover;
  object-position: center;

  border-radius: 8px;

  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 980px;
  width: 100%;
}

.bold_content {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  /* identical to box height, or 164% */

  color: #2c2c2c;
}

.blog_content {
  max-width: 900px;
  width: 100%;

  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.social_icons {
  display: flex;
  flex-direction: column;
  gap: 22px;

  @media (max-width: 768px) {
    flex-direction: row;
    gap: 22px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 42px;
  }

  .social_fb_icon {
    width: 32px;
    height: 32px;
  }
}

.blog_text {
  // max-width: 750px;
  width: 100%;
}

.bold_content {
  margin-bottom: 28px;
}

.blog_description {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  text-align: justify;

  color: #464240;
}

.dividing_line {
  border: 1px solid #b9b9b9;

  max-width: 980px;
  width: 100%;

  margin-top: 60px;
  margin-bottom: 60px;
}

.related_posts {
  width: 100%;

  .related_heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    /* identical to box height, or 142% */
    color: #2c2c2c;
  }
}

.blogs_wrapper {
  display: flex;
  flex-wrap: wrap;

  justify-content: space-between;

  margin-top: 28px;

  margin-bottom: 100px;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

.test_test {
  background-color: #2c2c2c;
}
