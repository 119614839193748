@import '../../../sassStyles/_mixins.scss';

.table_col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border: 1px dashed #cccccc;
  border-radius: 8px;
  width: 382px;
  height: 40px;
  margin-bottom: 12px;
  img {
    margin-left: 16px;
    cursor: pointer;
  }
  div {
    margin-left: 9px;
  }
  &:hover {
    border: 1px dashed #2a72dd;
  }
}

.confirm_button {
  @include btn(#2a72dd);
  width: 98px;
  height: 34px;
  border-radius: 4px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #ffffff;
  margin-left: 12px;
}

.cancel_button {
  @include btn(#ffffff, #8c959b);

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  width: 74px;
  height: 34px;

  border-radius: 4px;
}
.button_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.checkbox_label {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #8c959b;
}
