.page_wrapper {
  background: #f7f4f2;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  text-align: center;
}
.btn {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #464240;

  background: rgba(216, 31, 31, 0.05);

  border-radius: 8px 0px 0px 8px;
  width: 117px;
  height: 40px;
  outline: none;
  border: none;
  margin-top: 30px;
}
