.header_wrapper {
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  // position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 40px;
  z-index: 100;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 0;
  }
  .header_logo {
    cursor: pointer;
  }
}
.header_sticky{
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 1000;
}

.user_details {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;

  cursor: pointer;
}

.user_name {
  max-width: 100px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* identical to box height, or 157% */

  color: #000000;
  // display: none;
}

.dropdown_user_menu_container {
  position: relative;
}

.dropdown_menu {
  position: absolute;
  top: 60px;
  right: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 14px;
  padding-right: 14px;
  // display: none;
  z-index: 100;

  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  .user_details_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    min-height: 60px;
    padding-left: 5%;
  }

  .dropdown_menu_email {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 22px;
    /* identical to box height, or 200% */

    letter-spacing: 0.01em;

    color: #384852;
  }

  .dropdown_menu_name {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    letter-spacing: 0.01em;

    color: #222222;
  }

  .border_line {
    border: 1px solid #f2f2f2;
    width: 237px;
    margin-top: 10px;
    margin-bottom: 12px;
  }
}

.option {
  display: flex;
  min-height: 45px;
  align-items: center;
  gap: 10px;
}

.options_container {
  display: flex;
  flex-direction: column;
  // gap: 12px;
}
.avatars {
  display: inline-flex;
  flex-direction: row-reverse;
}
.avatar {
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 35px;
}
.avatar img {
  width: 100%;
  display: block;
}
.avatar:not(:last-child) {
  margin-left: -15px;
}
.user_icon {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.option_text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  color: #667584;
}

.logout_text {
  color: #fa3939;
}
.notification {
  cursor: pointer;
  position: relative;
}
.counter {
  position: absolute;
  top: -2px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  background-color: red;
  color: white;
  font-size: 12px;
  border-radius: 50%;
}

.activity_report {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #384852;
  padding: 0px 8px;
  cursor: pointer;
  &:hover {
    color: #2a72dd;
  }
}
.header_link_selected {
  color: #2a72dd;
}
