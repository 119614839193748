.page_wrapper {
  padding: 20px;
  @media screen and (max-width: 728px) {
    padding: 0px;
  }
}

.faq_page_wrapper {
  background: #f7f4f2;
  width: 100%;
  // padding-left: 125px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding: 20px;

  // @media (max-width: 768px) {
  //   padding-left: 0;
  // }
  @media screen and (max-width: 728px) {
    padding: 0px;
  }
}

.max_width {
  max-width: 1440px;
  width: 100%;
}

.faq_header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  margin-top: 55px;
  @media (max-width: 768px) {
    margin-top: 0px;
  }
  &_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 63px;
    /* identical to box height, or 150% */

    text-align: center;

    color: #2c2c2c;
    margin-bottom: 25px;
  }

  &_sub {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;

    color: #464240;
    margin-bottom: 25px;
  }

  &_buttons {
    display: flex;

    &_primary {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */

      display: flex;
      align-items: center;
      justify-content: center;
      color: #464240;

      background: rgba(216, 31, 31, 0.05);

      border-radius: 8px 0px 0px 8px;
      width: 117px;
      height: 40px;
      outline: none;
      border: none;
    }

    &_primary_active {
      color: #ffffff;

      background: #d81f1f;
    }

    &_secondary {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */

      display: flex;
      align-items: center;
      justify-content: center;

      color: #464240;
      background: rgba(216, 31, 31, 0.05);
      border-radius: 0px 8px 8px 0px;
      width: 117px;
      height: 40px;
      outline: none;
      border: none;
    }

    &_secondary_active {
      color: #ffffff;

      background: #d81f1f;
    }
  }
}

.faq_accordian {
  max-width: 900px;
  width: 100%;
  margin: auto;
  margin-top: 80px;
  @media (max-width: 768px) {
    margin-top: 40px;
    padding: 10px;
  }
}

.faq_accordian_item_header {
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 0px !important;
  padding: 10px 0;

  &_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 157% */

    color: #2c2c2c;
  }
}

.bottom_page_container {
  background: #f7f4f2;
  width: 100%;
  overflow: hidden;
  padding-top: 150px;

  display: flex;
  justify-content: center;

  flex-direction: column;
}

.cta_wrapper {
  width: 980px;
  height: 222px;

  background: #192d73;
  border-radius: 14px;

  padding-left: 50px;
  padding-right: 50px;

  display: flex;
  justify-content: center;
  gap: 58px;

  margin: auto;
  margin-bottom: 60px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.cta_detail_heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  /* or 150% */

  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    text-align: center;
  }
}

.cta_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cta_button_wrapper {
  background: #ffffff;
  border-radius: 8px;

  width: 135px;
  height: 38px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  margin-top: 26px;

  color: #d81f1f;

  display: flex;
  align-items: center;
  justify-content: center;
}

.cta_detail_description {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  text-align: justify;

  color: #ffffff;
  margin-top: 14px;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 150% */

    text-align: center;
  }
}

.faq_accordian_item_body_description {
  text-align: justify;
  padding: 0px 20px 0px 10px;
}
