.footer_wrapper {
  padding: 40px;
  // padding-right: 210px;
  max-width: 1180px;
  width: 100%;
  margin: auto;
}

.link {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #2c2c2c;
  cursor: pointer;

  &:hover {
    text-shadow: 0.3px 0 0 #2c2c2c;
  }
  .link_rights {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #2c2c2c;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.links {
  display: flex;
  gap: 38px;
  align-items: center;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
}

.field_button_wrapper {
  background: #ffffff;
  border-radius: 8px;

  width: 100%;
  max-width: 400px;
  height: 54px;

  display: flex;
  justify-content: space-between;

  align-items: center;

  padding-right: 8px;

  @media (max-width: 768px) {
    width: 100%;
  }

  cursor: pointer;
}

.input_field {
  border: none;
  outline: none;
  width: 70%;
  height: 100%;

  background-color: transparent;
  padding-left: 13px;
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #2c2c2c;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #2c2c2c;
}

::placeholder {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #2c2c2c;
}

.button_wrapper {
  background: rgba(216, 31, 31, 0.04);
  border: 1px solid #d81f1f;
  border-radius: 8px;
  width: 140px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #d81f1f;
}

.footer_cta_container {
  display: flex;
  justify-content: space-between;

  align-items: center;

  margin-top: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
}

.privacy {
  border-top: 1px solid #e7e7e7;
  padding-top: 20px;
  margin-top: 65px;
}

.footer_privacy_container {
  display: flex;
  justify-content: space-between;

  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
  }
}
