.landing_page_wrapper {
  background: #f7f4f2;
  width: 100%;
  //   padding-left: 125px;
  overflow: hidden;
}

.pricing_header {
  margin-top: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  &_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 63px;
    /* identical to box height, or 150% */

    text-align: center;

    color: #2c2c2c;
    margin-bottom: 25px;
  }
  &_sub {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;

    color: #464240;
    margin-bottom: 25px;
  }
  &_buttons {
    display: flex;

    &_primary {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */

      display: flex;
      align-items: center;
      justify-content: center;
      color: #464240;

      background: rgba(216, 31, 31, 0.05);

      border-radius: 8px 0px 0px 8px;
      width: 117px;
      height: 40px;
      outline: none;
      border: none;
    }
    &_primary_active {
      color: #ffffff;

      background: #d81f1f;
    }
    &_secondary {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */

      display: flex;
      align-items: center;
      justify-content: center;

      color: #464240;
      background: rgba(216, 31, 31, 0.05);
      border-radius: 0px 8px 8px 0px;
      width: 117px;
      height: 40px;
      outline: none;
      border: none;
    }
    &_secondary_active {
      color: #ffffff;

      background: #d81f1f;
    }
  }
  @media (max-width: 768px) {
    &_title {
      width: 80%;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      /* or 150% */

      text-align: center;

      color: #2c2c2c;
    }
  }
}

.pricing_table {
  margin: 50px 28px;
  &_container {
    margin: auto;
  }
  &_col1 {
    width: 400px;
    border-bottom: 40px solid transparent;
  }
  &_col2 {
    width: 232px;
    border-bottom: 40px solid transparent;
    border-right: 29px solid transparent;
    -webkit-background-clip: padding;
    -moz-background-clip: padding;
    background-clip: padding-box;
  }
  &_col3 {
    width: 232px;
    border-bottom: 40px solid transparent;
    border-right: 29px solid transparent;
    -webkit-background-clip: padding;
    -moz-background-clip: padding;
    background-clip: padding-box;
  }
  &_col4 {
    width: 232px;
    border-bottom: 40px solid transparent;
  }
  @media (max-width: 768px) {
    margin: 50px 0px;
  }
}

.price_card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 21px 21px 24px 21px;
  border-radius: 8px;
  width: 232px;
  &_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    /* identical to box height, or 136% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #2c2c2c;
    margin-bottom: 6px;
  }
  &_amount {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    &_value {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 39px;
      line-height: 58px;
      /* identical to box height, or 149% */

      display: flex;
      align-items: center;

      color: #2c2c2c;
      margin-right: 6px;
    }
    &_period {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */

      display: flex;
      align-items: center;

      color: #7e8183;
    }
  }
  &_button {
    width: 100%;
    height: 40px;
    border: 1px solid #d81f1f;
    border-radius: 8px;
    background-color: transparent;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    display: flex;
    align-items: center;

    color: #d81f1f;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    width: 316px;
    height: 187px;

    margin: 20px auto;
  }
}

.table_feature_row {
  height: 60px;
  border-bottom: 0.6px solid #ede1e1;
  &_feature {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    color: #000000;
    @media (max-width: 768px) {
      width: 75%;
      padding-left: 20px;
    }
  }
  &_standard,
  &_professional,
  &_enterprise {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    color: #2c2c2c;
  }
  &_standard {
    border-right: 29px solid #0000;
  }
  //   &_standard,
  //   &_professional {
  //     border-right: 29px solid transparent;
  //     -webkit-background-clip: padding;
  //     -moz-background-clip: padding;
  //     background-clip: padding-box;
  //   }
}
table {
  border-collapse: collapse;
}
