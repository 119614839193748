.heading {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: #ffffff;
  margin-bottom: 24px;
}

.featurecolumn {
  display: flex;
  flex-direction: column;
  margin-left: 58px;
}
.left_heading {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  /* or 150% */

  color: #ffffff;
}
.left_sub_heading {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.logo {
  margin-bottom: 20px;
}

.screen_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
}
