.container {
    max-width: 1920px;
    width: 100%;
    margin: auto;
}
.subContainer {
    // overflow: hidden;
}
.main {
    margin: auto;
}
  