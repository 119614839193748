.create_automation_navbar {
  height: 60px;
  width: 100%;

  background: #ffffff;
  display: flex;
  gap: 20px;
  align-items: center;
  padding-left: 50px;

  .create_automation_navbar_text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    /* identical to box height, or 142% */

    color: #222222;
  }
}

.form_container {
  background: #f5f5f5;
  padding: 30px;

  display: flex;

  gap: 70px;

  min-height: 90vh;
}
