.business_details {
  display: flex;
  &_left {
    width: calc(100% - 58px);
    margin-right: 10px;
  }
  &_right {
    width: calc(100% - 58px);
  }
  margin-bottom: 20x;
}
.report_error {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: #f64141;
  cursor: pointer;
}
.section_header {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  /* identical to box height, or 214% */

  color: #222222;
  margin-bottom: 16px;
  //   margin-top: 10px;
}

.business_detail_pill_header {
  font-family: 'Lato';
  font-style: normal;
  line-height: 22px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 12px;

  color: #384852;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.business_detail_pill_value {
  font-family: 'Lato';
  font-style: normal;
  line-height: 22px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 14px;

  color: #222222;
  cursor: pointer;
  &:hover {
    color: #797676;
  }
}
.business_detail_pill_value_phone {
  width: 230px;
  height: fit-content;
  left: 0px;
  top: 35px;
  overflow: hidden;
  text-overflow: ellipsis;

  background: #eaedf6;
  border-radius: 8px;
  padding: 12px;

  p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* identical to box height, or 183% */

    color: #384852;
    margin-bottom: 2px;
  }
  span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    color: #222222;
    width: calc(200px - 12px);
  }
}
