@import '../../sassStyles/_mixins.scss';
.page_container {
  min-height: calc(100vh - 62px);
  background: #fafafa;
  width: 100%;
}

.avatars {
  display: inline-flex;
  flex-direction: row-reverse;
}
.avatar {
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 35px;
}
.count_avatar {
  position: relative;
  background: #f2f2f2;
  right: 10%;

  border: 1px solid #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #2a72dd;
}
.avatar:not(:last-child) {
  margin-left: -15px;
}

.avatar img {
  width: 100%;
  display: block;
}

.page_wrapper {
  max-width: 1280px;
  width: 100%;
  padding: 21px 60px;
  background: #fafafa;
  margin: auto;
}

.page_name {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height; or 142% */

  color: #000000;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.add_project {
  @include btn(#2a72dd);
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  color: #ffffff;
  border-radius: 8px;
  border: none;
  width: 135px;
  height: 44px;
}
.search_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1170px;
}

.search_input {
  width: 438px;
  height: 46px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.search_input input[type='text'] {
  padding: 12px 40px;
  font-size: 17px;
  border: none;

  width: 100%;
  outline: none;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.search_input button {
  width: 20%;
  padding: 10px;

  color: white;
  font-size: 17px;
  border: 1px solid grey;
  border-left: none;
  cursor: pointer;
}

.search_input input[type='text']::-webkit-input-placeholder {
  /* Edge */
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Input header */

  color: #8c959b;
}

.search_input input[type='text']::placeholder {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Input header */

  color: #8c959b;
}

.user_icon {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.project_container {
  margin: 20px 0px 20px 0px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1170px;
  gap: 20px;
}

.card {
  width: 373px;
  height: 167px;
  background: #ffffff;
  border-radius: 14px;
  padding: 24px 24px 21px 24px;
  position: relative;

  -webkit-transition: 0.3s ease-out;
  -moz-transition: 0.3s ease-out;
  -ms-transition: 0.3s ease-out;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out;

  cursor: pointer;
  &_date {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* identical to box height, or 183% */

    /* #646D72 */

    color: #646d72;
    margin-bottom: 10px;
  }
  &_name {
    width: 300px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 30px;
    /* identical to box height, or 167% */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #000000;
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
}

.card_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 21px;
  width: calc(100% - 50px);
}
.card_footer_next {
  -webkit-transition: transform 0.3s ease-out;
  -moz-transition: transform 0.3s ease-out;
  -ms-transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.card:hover .card_footer_next {
  -webkit-transform: translateX(4px);
  -moz-transform: translateX(4px);
  -ms-transform: translateX(4px);
  -o-transform: translateX(4px);
  transform: translateX(4px);
}

.loader_container {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
.empty_state {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  height: 410px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty_text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */

  color: #222222;
  margin-top: 14px;
  margin-bottom: 18px;
}
.not_found {
  width: 100%;
  height: 410px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
