.wrapper {
  width: 274px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 24px;
}
.header {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  margin-left: 1.5rem;
  margin-top: 1rem;
}
