@import '../../../sassStyles/_mixins.scss';

.table_col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  border: 1px dashed #cccccc;
  border-radius: 8px;
  width: 382px;
  height: 40px;
  margin-bottom: 12px;
  img {
    margin-left: 16px;
    cursor: pointer;
  }
  div {
    margin-left: 9px;
  }
  &:hover {
    border: 1px dashed #2a72dd;
  }
}

.confirm_button {
  @include btn(#2a72dd);
  width: 98px;
  height: 34px;
  border-radius: 4px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #ffffff;
  margin-left: 12px;
}

.cancel_button {
  @include btn(#ffffff, #8c959b);

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  width: 74px;
  height: 34px;

  border-radius: 4px;
}
.create_button {
  @include btn(#ffffff, #8c959b);

  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  border: 1px solid #2a72dd;
  border-radius: 4px;
  width: 124px;
  height: 34px;

  border-radius: 4px;
  color: #2a72dd;
}
.button_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.checkbox_label {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #8c959b;
}
.create_list_form {
  display: flex;
  flex-direction: column;
  & input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    height: 50px;
    outline: 0;
    padding: 0px 10px;
    &:focus {
    }
  }
}

.list_div {
  display: flex;
  height: 50px;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0px;
}
.list_div_radio {
  accent-color: #2a72dd;
}
.list_div_item {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  & div {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    color: #384852;
    margin-top: 15px;
  }
  & p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    color: rgba(56, 72, 82, 0.5);
  }
}
.error_msg {
  margin-top: 1rem;
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  // text-align: center;
  max-width: fit-content;

  /* CC – White */
  color: #e4513d;
}
