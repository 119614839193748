.dropdown_menu {
  position: absolute;
  padding: 24px;
  padding-right: 0px;
  top: 40px;
  right: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  min-height: 100px;
  // display: none;
  width: 403px;
  z-index: 1000;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 8px;
}
.header {
  height: 40px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  padding-right: 14px;

  color: #222222;
}
.notificaltion_wrapper {
  width: 100%;
  max-height: 421px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.notification_item {
  height: 50px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  &:hover {
    background: #f9fbff;
  }
}
.notification_item_left {
  display: flex;
  flex-direction: column;
}

.reminder {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #222222;
}
.reminder_business {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
  /* identical to box height, or 200% */

  letter-spacing: 0.01em;

  color: #384852;
}
.timer {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  letter-spacing: 0.01em;

  color: #384852;
}
.no_notification {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: hsl(0, 0%, 80%);
}
