.form_heading {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 34px;
  color: #000000;
  max-width: 655px;
  width: 100%;
  margin-top: 20px;
}

.form_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 66%;
  // height: 100vh;
  gap: 32px;
  @media(max-width:768px){
    width: 100%;
  }
}

.two_fields {
  display: flex;
  width: 100%;
  justify-content: space-between;
  // margin-bottom: 12px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
}

.single_field {
  display: inline-block;
  width: 302px;
  @media screen and (max-width: 800px) {
    max-width: 600px;
    width: 100%;
    margin-bottom: 32px;
  }
}

.form_container {
  max-width: 655px;
  width: 100%;
}
.request_access_button_wrapper {
  margin-top: 3rem;
}
