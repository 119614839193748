@import '../../../sassStyles/_mixins.scss';
.filters_container_bg {
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px 0px;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
}

.filters_container {
  display: flex;
  flex-direction: row;
  gap: 13px;
  background: #ffffff;
  height: 60px;

  align-items: center;
  // max-width: 1440px;
  margin: 0px auto;
  z-index: 99;
}

.min_max_range_slider {
  // width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.value_button {
  background: #fefefe;
  border: 1px solid #d1dadf;
  border-radius: 4px;
  width: 95px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #384852;
  padding-left: 10px;
  padding-right: 10px;
}

.value_button:hover {
  cursor: pointer;
}
.state_dropdown_filter {
  z-index: 100;
}

.state_dropdown {
  background: #fefefe;
  border: 1px solid #d1dadf;
  border-radius: 4px;
  height: 30px;

  // Style font for dropdown
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  padding-left: 10px;
  padding-right: 10px;
  /* identical to box height, or 183% */
  cursor: pointer;

  color: #384852;

  // Style for dropdown options
  .dropdown_options {
    background: #fefefe;
    border: 1px solid #d1dadf;
    border-radius: 4px;
    height: 30px;

    // Style font for dropdown
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    padding-left: 10px;
    /* identical to box height, or 183% */
  }
}

.filters_text {
  margin-left: 5px;
}

.filters_text_active {
  margin-left: 5px;
  color: #2a72dd;
}

.save_to_list_button {
  @include btn(#2a72dd);
  border: 0px solid #eee;
  background: #2a72dd;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-left: 16px;
  padding-right: 16px;
  height: 34px;
  cursor: pointer;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #ffffff;
}
.compose_email_button {
  @include btn(#2a72dd);
  border: none;
  background: #2a72dd;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-left: 16px;
  padding-right: 16px;
  height: 34px;
  cursor: pointer;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #ffffff;
}
.number_selected_filters {
}

.active_button {
  // background: #2a72dd;
  border: 1px solid #2a72dd;
  gap: 5px;
  // color: #ffffff;
}

.cicle_border {
  border: 1px solid #2a72dd;
  border-radius: 50%;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2a72dd;
  // margin-left: 5px;
  // margin-right: 5px;
}

.applied_filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;

  // width: 92px;
  height: 28px;

  background: #eaeef4;
  border: 1px solid #cdd5dd;
  border-radius: 100px;
}

.applied_filters_container {
  display: flex;
  gap: 14px;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 14px;
}

.applied_filters {
  display: flex;
  flex-direction: row;
  gap: 10px;
  // align-items: center;
  // justify-content: center;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  // max-height: 60px;
  flex-wrap: wrap;

  max-width: 1440px;
  width: 1440px;
  margin: 0px auto;

  /* Text_Color */

  color: #667584;
  .applied_filter_close {
    cursor: pointer;
  }
}

.clear_filters_text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #2a72dd;
}
.zipcode_input_filter {
  width: 130px;
  height: 35px;
  font-size: 12px;
  font-family: 'Lato';
  border-radius: 5px;
  padding-left: 10px;
  border: 1px solid #cccccc;

  &:focus {
    outline: 2px solid #2684ff;
  }
  &::placeholder {
    // text-indent: 10px;
    font-size: 12px;
    font-family: 'Lato';
    color: #706b6b;
  }
}
.zipcode_input_filter::placeholder {
}

.toggle_type {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  height: 28px;
  transition: 1.5s;

  &_national {
    // @include btn(#2a72dd);
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    border: 1px solid #2a72dd;
    width: 126px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    cursor: pointer;
    background: #ffffff;
  }
  &_local {
    // @include btn(#2a72dd);

    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    border: 1px solid #2a72dd;
    width: 126px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    cursor: pointer;
    background: #ffffff;
  }
  &_selected {
    color: #ffffff;
    background: #2a72dd;
  }
}
.clear_all {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* identical to box height, or 157% */

  color: #2a72dd;
}
.list_name {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #222222;
  margin-bottom: 6px;
}
.circular_hover {
  cursor: pointer;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  margin-right: 10px;
}
.circular_hover:hover {
  cursor: pointer;
  border-radius: 50%;
  background-color: #dce4f1;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}