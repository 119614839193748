.max_width {
  width: 100%;
  overflow: hidden;
  margin: 0px auto;

  background-color: #fafafa;
}
.map_container {
  margin-left: 16px;
  width: 500px;
  position: relative;
  opacity: 1;
  transition: 1.5s;

  .mapboxgl-ctrl {
    display: none;
  }
}
.map_container_collaped {
  margin-left: -16px;
  width: 0px;
  transition: 1.5s;
  .mapboxgl-ctrl {
    display: none;
  }
}
.map_arrow {
  position: absolute;
  width: 24px;
  height: 40px;
  right: 0;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  background: #ffffff;
  box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px 0px 0px 4px;
  z-index: 999;
}
.map_arrow_collapsed {
  position: absolute;
  width: 24px;
  height: 40px;
  right: 0%;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  background: #ffffff;
  box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px 0px 0px 4px;
  z-index: 999;
}
.dashboard_body_bg {
}
.dashboard_body {
  max-width: 1440px;
  margin: 0px auto;

  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  max-height: calc(100vh - 180px);
  transition: 1.5s;
}
.table_container {
  max-width: 946px;
  flex-grow: 1;
  transition: 1.5s;
}
.table_container_national {
  max-width: 100%;
  flex-grow: 1;
  transition: 1.5s;
}

.rdt_TableBody::-webkit-scrollbar {
  width: 1em;
  height: 6px;
}

.rdt_TableBody::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.rdt_TableBody::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.marker {
  font-size: xx-large;
}
.list_name {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #222222;
  margin-bottom: 6px;
}
.circular_hover {
  cursor: pointer;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  margin-right: 10px;
}
.circular_hover:hover {
  cursor: pointer;
  border-radius: 50%;
  background-color: #dce4f1;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
