.form_container {
  padding: 18px 32px;
  height: fit-content;
  gap: 13px;
  background: #ffffff;
  border-radius: 14px;
  width: 700px;
  margin-bottom: 20px;
}

.form_container_due {
  padding: 16px 32px 40px 32px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 42px;
  background: #ffffff;
  border-radius: 14px;
  width: 700px;
  margin-bottom: 20px;
}

.form_title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 127% */

  color: #222222;
}

.note {
  width: 100%;
  background: #f5f9ff;
  border-radius: 8px;
  text-align: justify;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  padding: 12px;

  color: #637692;
  margin-bottom: 12px;
}
.spaceAndCost_subsection {
  margin-top: 1rem;
  border-radius: 8px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */
}

.form_field {
  // width: 637px;
}

.two_fields {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
}
.three_fields {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
}

.single_field {
  width: 300px;
}
.three_field_single {
  width: 185px;
}

.add_more {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  color: #2a72dd;
  margin-top: 16px;
  cursor: pointer;
}

.add_project_footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.button_wrapper {
  display: flex;
  width: 296px;
  justify-content: flex-end;

  button {
    max-width: 140px;
  }
  button:first-of-type {
    margin-right: 16px;
  }
}

.two_fields_stacked {
  display: flex;
  flex-direction: column;
  width: 100%;
}
