.field_label{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* identical to box height, or 183% */


    color: #8C959B;
    margin-top: 16px;
    margin-bottom: 8px;
}

.tag{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */


    color: #7E7A7A;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 10px;

    border: 1px solid #DEE2E6;
    border-radius: 100px;
    cursor: pointer;
}

.tags_wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 8px;
}

.active_tag{
    background: #2A72DD;
    color: #fff;
}