$base-color: #f4f4f4;
$shine-color: rgba(229, 229, 229, 0.8);
$animation-duration: 2s;
$avatar-offset: 52 + 16;

@mixin background-gradient {
  background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
  background-size: 600px;
}

@import '../../sassStyles/mixins';

.table_container {
  position: relative;
  width: 100%;
}
.table_container_national {
  position: relative;
  width: 100%;
}

.table_container > div::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

.table_setting {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 10px;
  z-index: 2;
  background: #f1f4ff;
}

.side_nav {
  margin-bottom: 0px;
  text-transform: capitalize;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #2a72dd;
}

.side_pane_body {
  padding: 24px;
  height: 100%;
  overflow-y: scroll;
}

.side_pane_body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.side_pane_body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  /* background-color: #f5f5f5; */
}

.side_pane_body::-webkit-scrollbar-thumb {
  background-color: #6396e2;
}
.sidepane_title {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    width: 70%;
    overflow: hidden;

    text-overflow: ellipsis;
  }
}
.set_reminder {
  font-family: 'Lato';
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 157% */
  text-decoration: underline;
  @include links(#2a72dd);
  color: #2a72dd;
}

//business detail pane
.business_details {
  display: flex;

  &_left {
    width: calc(100% - 58px);
    margin-right: 10px;
  }

  &_right {
    width: calc(100% - 58px);
  }
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
  border-color: #ccc;
  width: 100%;
}

.tg td {
  font-family: Arial, sans-serif;
  font-size: 14px;
  padding: 10px 5px;
  border-style: solid;
  border-width: 0px;
  overflow: hidden;
  word-break: normal;
  border-color: #ccc;
  color: #333;
  background-color: #fff;
}

.tg th {
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  padding: 10px 5px;
  border-style: solid;
  border-width: 0px;
  overflow: hidden;
  word-break: normal;
  border-color: #ccc;
  color: #333;
  background-color: #f0f0f0;
}

.line {
  float: left;
  width: 150px;
  height: 16px;
  margin-top: 12px;
  border-radius: 7px;

  @include background-gradient;
  animation: shine-lines $animation-duration infinite ease-out;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 140px;
  }
}
