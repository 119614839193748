.card_wrapper {
  display: flex;
  align-items: center;
  width: 274px;
  height: 100px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 24px;
}
.image {
  margin: 0px 20px;
}
.count {
  width: 100%;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  color: #222222;
}
.description {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #667584;
  margin-top: 5px;
}
.count_percentage {
  font-size: 16px;
  color: #393b3d;
  margin-left: 25px;
}
