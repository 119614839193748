.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  // padding-left: 30px;
  margin: auto;
}
.thankstext {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 10px;
}
.smalltext {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #8a8a8a;
  margin-bottom: 24px;
}
.emoji {
  margin-bottom: 12px;
}
.reach {
  color: #d81f1f;
}
.tenants {
  color: #192d73;
}
.btn {
  width: 180px;
}
