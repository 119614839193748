@import '../../../sassStyles/_mixins.scss';

.button {
  width: 100%;
}

.button:hover {
  cursor: pointer;
}

.btn_primary {
  @include btn(#2a72dd);
  height: 45px;
  background: #2a72dd;
  border-radius: 8px;
  border: none;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  color: #ffffff;
}

.btn_secondary {
  @include btn(#ffffff);
  height: 45px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #2a72dd;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  color: #2a72dd;
}

.grey_button {
  @include btn(#ffffff);
  height: 45px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #8c959b;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  color: #8c959b;
}

.ldsRing {
  display: inline-block;
  position: relative;
  width: 22px;
  height: 22px;
}

.ldsRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 22px;
  height: 22px;
  //   margin: 8px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}

.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}

.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// .disabled {
//   background: #75a1e2;
// }