.landing_page_wrapper {
  background: #f7f4f2;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding-left: 0;
  }
}

.max_width {
  max-width: 1440px;
  width: 100%;
  // overflow: hidden;
}

.page_wrapper {
  padding: 20px;

  @media (max-width: 768px) {
    padding: 0px;
  }
}

.heading_cover_image {
  width: 977px;
  height: 250px;

  background: #192d73;
  border-radius: 12px;

  overflow: visible;

  @media (max-width: 768px) {
    width: 100%;
    width: 345px;
  }
}

.content_wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
}

.heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 64px;
  color: #ffffff;

  margin-bottom: 26px;
  margin-top: 40px;
  @media (max-width: 768px) {
    width: 224px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    /* or 150% */

    text-align: center;

    color: #ffffff;
  }
}

.sub_heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  color: #ffffff;
  @media (max-width: 768px) {
    font-family: 'Montserrat';
    width: 213px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    text-align: center;

    color: #ffffff;
  }
}

.heading_cover_image {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.search_field_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  background: #ffffff;
  border-radius: 8px;
  margin-top: 70px;
  width: 720px;
  padding-right: 16px;
  padding-left: 26px;
  @media (max-width: 768px) {
    width: 321px;
    height: 60px;
    margin-top: 2px;
    padding-right: 16px;
    padding-left: 8px;
  }
}

.input_field {
  border: 0;
  outline: none;
  background-color: transparent;
  width: 100%;
  height: 60px;
  padding-left: 10px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  color: #464240;
  @media (max-width: 768px) {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 22px;
    /* identical to box height, or 220% */

    color: #464240;
  }
}

.search_button {
  background: rgba(216, 31, 31, 0.04);
  border: 1px solid #d81f1f;
  border-radius: 8px;
  width: 130px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  /* identical to box height, or 157% */

  color: #d81f1f;
  @media (max-width: 768px) {
    display: none;
  }
}

.sic_codes_list {
  padding-left: 65px;
  padding-right: 65px;
  margin-top: 57px;
  width: 976px;
  min-height: 300px;
  background: #ffffff;
  border-radius: 8px;
  padding-top: 45px;
  @media (max-width: 768px) {
    width: 359px;
    padding: 0px 10px;
  }

  .heading_wrapper {
    padding-left: 35px;
    display: flex;

    .list_heading {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      /* identical to box height */
      color: #000000;
      min-width: 168px;
      @media (max-width: 768px) {
        min-width: 114px;
      }
    }
    @media (max-width: 768px) {
      padding: 15px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }
  }

  .sub_heading_wrapper {
    padding-left: 35px;
    display: flex;
    margin-top: 46px;
    margin-bottom: 14px;
    .list_subheading {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      min-width: 168px;
      color: #464240;
      @media (max-width: 768px) {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        color: #464240;
        min-width: 114px;
      }
    }
    @media (max-width: 768px) {
      margin-top: 10px;
      padding-left: 15px;
    }
  }
}

.border_bottom {
  padding-bottom: 20px !important;
  padding-top: 20px !important;
  border-bottom: 1px solid #e3e3e3;
}

.individual_sic_code {
  display: flex;
  padding-bottom: 8px;
  padding-top: 8px;

  .checkbox_wrapper {
    min-width: 35px;
  }

  .sic_code {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    color: #464240;
    min-width: 168px;
    @media (max-width: 768px) {
      min-width: 114px;
    }
  }

  .sic_code_description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    color: #464240;
  }
}

.checkbox {
  --background: #fff;
  --border: #d1d6ee;
  --border-hover: #bbc1e1;
  --border-active: #192d73;
  --tick: #fff;
  position: relative;
  input,
  svg {
    width: 21px;
    height: 21px;
    display: block;
  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: var(--background);
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    transition: box-shadow 0.3s;
    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
    &:hover {
      --s: 2px;
      --b: var(--border-hover);
    }
    &:checked {
      --b: var(--border-active);
    }
  }
  svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, var(--border-active));
    position: absolute;
    top: 0;
    left: 0;
    width: 21px;
    height: 21px;
    transform: scale(var(--scale, 1)) translateZ(0);
  }
  &.path {
    input {
      &:checked {
        --s: 2px;
        transition-delay: 0.4s;
        & + svg {
          --a: 16.1 86.12;
          --o: 102.22;
        }
      }
    }
    svg {
      stroke-dasharray: var(--a, 86.12);
      stroke-dashoffset: var(--o, 86.12);
      transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
    }
  }
  &.bounce {
    --stroke: var(--tick);
    input {
      &:checked {
        --s: 11px;
        & + svg {
          animation: bounce 0.4s linear forwards 0.2s;
        }
      }
    }
    svg {
      --scale: 0;
    }
  }
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.selected_code_footer {
  background: #ffffff;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 70px;
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-right: 100px;
  justify-content: flex-end;
  gap: 40px;
  @media (max-width: 768px) {
    padding-right: 0px;
    justify-content: space-around;
  }
}

.selected_text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  color: #464240;
}

.button {
  background: rgba(216, 31, 31, 0.04);
  border: 1px solid #d81f1f;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 140px;
  height: 40px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  /* identical to box height, or 157% */

  color: #d81f1f;
}
.no_result_found {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  /* or 150% */

  color: #a9a3a3;
}
