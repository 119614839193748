.header {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}
.wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .switcher {
    display: flex;
    width: 28%;
    justify-content: space-between;
    .tabs {
      width: 45px;
      height: 20px;
      padding: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 13px;
      color: #5f6165;
      border-radius: 20px;
      cursor: pointer;
    }
    .selected_tabs {
      color: #ffffff;
      background: #2a72dd;
    }
  }
}
.tabs:hover {
  font-weight: 800;
}
.bar_chart {
  width: 563px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 24px;
}
.bar_chart_custom {
  width: calc(100% - 10px);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 24px;
}
