.note_container {
  margin-bottom: 100px;
  & textarea {
    width: 478px;
    height: 216px;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 12px 27px;
    &::placeholder {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      /* identical to box height, or 183% */

      color: #384852;
    }
    &:focus-visible {
      outline-color: #c1d4f1;
    }
  }
}

.note_list {
  & .note_item {
    & .note_info {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      /* identical to box height, or 183% */
      display: flex;
      align-items: center;
      color: #384852;
      margin-bottom: 4px;
      margin-top: 10px;
      & img {
        margin: 0px 6px;
      }
    }
    & .note_text {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */

      color: #222222;
      margin-bottom: 10px;
    }
    & .note_divider {
      width: 467px;
      height: 2px;

      background: #f4f4f4;
    }
  }
}
