@mixin createEmailFont {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.create_template_container {
  margin: 24px 73px 0px 24px;
  border-radius: 8px;
  background: #ffffff;
  padding: 24px 24px 24px 24px;
  height: calc(100% - 24px);
  overflow-y: auto;
}
.header {
  display: flex;
  justify-content: space-between;
}
.heading {
  @include createEmailFont;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.01em;

  color: #222222;
}
.heading_info {
  @include createEmailFont;
  line-height: 14px;
  letter-spacing: 0.01em;
  /* Text_Color */
  color: #667584;
}
.form_container {
  // margin-right: 2rem;
  position: relative;
}
.attachfile_wrapper {
  @include createEmailFont;
  margin-top: 0.5rem;
  line-height: 22px;
  color: #2a72dd;
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  .attachment_img {
    margin-right: 0.6rem;
  }
}
.button_wrapper {
  position: absolute;
  top: -26px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  // margin-top: 20px;
  gap: 20px;
}

.submit_button {
  width: fit-content;
  padding-left: 22px;
  padding-right: 22px;
}
.applied_filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;

  // width: 92px;
  height: 28px;

  background: #eaeef4;
  cursor: pointer;
}

.applied_filters {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  flex-wrap: wrap;
  margin: 10px 0 0 0;
  color: #667584;
  margin-bottom: 0px;
  .applied_filter_close {
    cursor: pointer;
  }
}
.applied_filter_text {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer !important;
}
