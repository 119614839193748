.two_fields {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
}

.single_field {
  width: 580px;
  margin-bottom: 1rem;
}

.form_wrapper {
  padding: 0 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button_wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.submit_button {
  width: fit-content;
  padding-left: 32px;
  padding-right: 32px;
}

.checkbox_label {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #282842;
  width: 500px;
}
.checkbox_label_description {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  letter-spacing: 0.01em;

  color: #7f7f89;
  position: absolute;
  left: 0;
  width: 500px;
}
.succCheck {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  img {
    margin-bottom: 9px;
  }
  h2 {
    margin-bottom: 12px;
    color: #3a3a3a;

    font-family: 'Lato';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  p {
    color: rgba(0, 0, 0, 0.5);

    text-align: center;
    font-family: 'Lato';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }
}
