.container {
  width: 467px;
  height: fit-content;
  margin-bottom: 16px;
  background: #eaedf6;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-family: 'Lato';
  font-style: normal;
  line-height: 22px;
  padding: 8px 16px;
  .date_time {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;

    color: #384852;
    display: flex;
    justify-content: space-between;
  }
  .text {
    font-weight: 500;
    font-size: 14px;

    color: rgba(34, 34, 34, 0.5);
  }
}
.read_tag {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  text-transform: uppercase;
  width: 55px;
  height: 20px;
  color: #ff8b37;
  background: #fff8f2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reply_tag {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  text-transform: uppercase;
  width: 55px;
  height: 20px;
  color: #37abff;
  background: #f2faff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email_status {
  display: flex;
  gap: 5px;
}
